export default {
    endpoints: {
        companies: 'companies',
        csv: 'csv',
        export: 'export',
        industries: 'industries',
        login: 'login',
        reports: 'reports',
        sectors: 'sectors',
        tasks: 'tasks',
        regions: 'regions',
        query: 'query',
        mcgroups: 'mcgroups',
        code: 'reports/code',
        patching: 'patching'
    }
};
