import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';
import Vue from 'vue';

export default ({app, $config}) => {
    if (process.client) {
        try {
            Sentry.init({
                Vue,
                dsn: $config.sentry.dsn,
                integrations: [
                    new BrowserTracing({
                        router: app.router,
                        tracingOrigins: [/^https:\/\/.*cyberhedge.*$/, /^\//],
                    })
                ],
                tracesSampleRate: 0.1,
                environment: $config.sentry.environment,
                attachProps: true,
                logErrors: true,
            });

            Vue.config.errorHandler = (error, vm, info) => {
                Sentry.captureException(error, {
                    extra: {
                        componentName: vm.$options.name,
                        info: info
                    }
                });
            };
        } catch (error) {
            console.error('Failed to initialize Sentry:', error);
        }
    }
}
