import Vue from 'vue'

import {config, library} from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
config.autoAddCss = false;

/**
 * FontAwesome Light Library
 *
 * This is our default library so we're importing all the icons for future use.
 */
import { fal } from '@fortawesome/pro-light-svg-icons'
library.add(fal)

/**
 * FontAwesome Brands Library
 *
 * As our use of Brands is very limited we're making explicit calls to import icons we want to use.
 */
import { faBitcoin } from '@fortawesome/free-brands-svg-icons';
import { faCcAmex } from '@fortawesome/free-brands-svg-icons';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faMonero } from '@fortawesome/free-brands-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { faPeriscope } from '@fortawesome/free-brands-svg-icons';
import { faSkype } from '@fortawesome/free-brands-svg-icons';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { faSnapchat } from '@fortawesome/free-brands-svg-icons';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faViber } from '@fortawesome/free-brands-svg-icons';
import { faVk } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
library.add(faBitcoin,faCcAmex,faCcMastercard,faCcVisa,faDiscord,faEthereum,faFacebook,faFacebookMessenger,faInstagram);
library.add(faLinkedin,faMonero,faPaypal,faPeriscope,faSkype,faSlack,faSnapchat,faTelegramPlane,faTwitter,faViber,faVk,faWhatsapp);

/**
 * FontAwesome Solid Library
 *
 * As our use of Solid is very limited we're making explicit calls to import icons we want to use.
 */
import { faBookmark } from '@fortawesome/pro-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { faFolderPlus } from '@fortawesome/pro-solid-svg-icons';
library.add(faBookmark,faExclamationTriangle,faFolderPlus);

/**
 * Register & Export
 */
Vue.component('font-awesome-icon', FontAwesomeIcon);
