//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'SiteNavigation',
    computed: {
        loggedIn () {
            return this.$store.state.auth.loggedIn;
        }
    },
    data() {
        return {
            navigation: [
                {
                    title: 'Companies',
                    link: 'company',
                },
                {
                    title: 'Report Editor',
                    link: 'code',
                },
            ]
        }
    }
}
