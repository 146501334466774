//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SiteNavigation from "../components/SiteNavigation";

export default {
    name: 'Layout',
    components: {
        SiteNavigation
    }
}
